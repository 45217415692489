// sidebar
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN'
export const SET_SIDEBAR_DOCKED = 'SET_SIDEBAR_DOCKED'
export const SET_SIDEBAR_HIDE = 'SET_SIDEBAR_HIDE'

// sidebar content
export const SET_SIDEBAR_CONTENT_ENTRY = 'SET_SIDEBAR_CONTENT_ENTRY'
export const SET_SIDEBAR_CONTENT_STRUCTURE = 'SET_SIDEBAR_CONTENT_STRUCTURE'
export const ON_SIDEBAR_CONTENT_EXPANDED = 'ON_SIDEBAR_CONTENT_EXPANDED'
export const ON_SIDEBAR_CONTENT_SELECTED = 'ON_SIDEBAR_CONTENT_SELECTED'

// anchor
export const SET_ANCHOR_OPEN = 'SET_ANCHOR_OPEN'
export const SET_ANCHOR_HIDE = 'SET_ANCHOR_HIDE'

// menu
export const ON_CHANGE_MENU_STATE = 'ON_CHANGE_MENU_STATE'

// product
export const ON_CHANGE_PRODUCT = 'ON_CHANGE_PRODUCT'